import React, {useEffect, useCallback} from 'react';
import {DataGridPro, useGridApiRef} from '@mui/x-data-grid-pro';

const StatefulDataGrid = props => {
    const {apiRef: rawApiRef, stateId, rows, ...rest} = props;
    if (!stateId) {
        throw new Error('StatefulDataGrid requires a stateId prop');
    }

    const apiRef = rawApiRef || useGridApiRef();

    const handleStateChange = useCallback(() => {
        if (!stateId) {
            return;
        }
        
        const currentState = apiRef.current.exportState();
        localStorage.setItem(`statefulDataGrid-${stateId}`, JSON.stringify(currentState));
    }, [apiRef, stateId]);

    useEffect(() => {
        const state = JSON.parse(localStorage.getItem(`statefulDataGrid-${stateId}`) || null);
        if (state) {
            apiRef.current.restoreState(state);
        }
    }, [apiRef, stateId]);

    return (
        <DataGridPro
            apiRef={apiRef}
            onColumnVisibilityModelChange={handleStateChange}
            onSortModelChange={handleStateChange}
            onColumnResize={handleStateChange}
            onColumnWidthChange={handleStateChange}
            onColumnOrderChange={handleStateChange}
            rows={rows}
            {...rest}
        />
    );
};

export default StatefulDataGrid;