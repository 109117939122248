import React, {useState} from 'react';
import {InputAdornment, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import TextField from './TextField';

export default function ControlledPasswordField(props) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            autoCapitalize="none"
            autoCorrect="off"
            autoComplete="password"
            {...props}
            {...!showPassword && {
                type: 'password',
                textcontenttype: 'password',
                securetextentry: 'true'
            }}
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    )
                }
            }}
        />
    );
};