import {alpha} from '@mui/material/styles';
import {gray, orange, green, red, blue} from '../themePrimitives';

export const feedbackCustomizations = {
    MuiAlert: {
        styleOverrides: {
            root: ({theme}) => ({
                borderRadius: 10,
                color: (theme.vars || theme).palette.text.primary,
                border: '1px solid',
                '& .MuiAlert-message': {
                    flex: 1
                },
                '& .MuiAlert-action': {
                    padding: 0
                },
                '& .MuiButton-text': {
                    fontWeight: 'bold'
                },
                '& .MuiButton-colorInherit': {
                    boxShadow: 'none',
                    fontWeight: 'bold'
                },
                '& .MuiButton-root': {
                    height: 'auto',
                    minHeight: '2.25rem',
                    marginLeft: 'calc(2 * var(--template-spacing))'
                },
                variants: [
                    {
                        props: {
                            severity: 'normal'
                        },
                        style: {
                            backgroundColor: gray[50],
                            border: `1px solid ${alpha(gray[200], 0.5)}`,
                            '& .MuiAlert-icon': {
                                display: 'none'
                            },
                            '& .MuiButton-text': {
                                '&:hover': {
                                    backgroundColor: alpha(gray[300], 0.5)
                                }
                            },
                            '& .MuiButton-colorInherit': {
                                backgroundColor: alpha(gray[200], 0.5),
                                '&:hover': {
                                    backgroundColor: alpha(gray[300], 0.5)
                                }
                            }
                        }
                    },{
                        props: {
                            severity: 'warning'
                        },
                        style: {
                            backgroundColor: orange[50],
                            border: `1px solid ${alpha(orange[200], 0.5)}`,
                            '& .MuiAlert-icon': {
                                color: orange[500]
                            },
                            '& .MuiButton-text': {
                                '&:hover': {
                                    backgroundColor: alpha(orange[300], 0.5)
                                }
                            },
                            '& .MuiButton-colorInherit': {
                                backgroundColor: alpha(orange[200], 0.5),
                                '&:hover': {
                                    backgroundColor: alpha(orange[300], 0.5)
                                }
                            }
                        }
                    },
                    {
                        props: {
                            severity: 'info'
                        },
                        style: {
                            backgroundColor: blue[100],
                            border: `1px solid ${alpha(blue[300], 0.5)}`,
                            '& .MuiAlert-icon': {
                                color: blue[500]
                            },
                            '& .MuiButton-text': {
                                '&:hover': {
                                    backgroundColor: alpha(blue[200], 0.5)
                                }
                            },
                            '& .MuiButton-colorInherit': {
                                backgroundColor: alpha(blue[200], 0.5),
                                '&:hover': {
                                    backgroundColor: alpha(blue[300], 0.5)
                                }
                            }
                        }
                    },
                    {
                        props: {
                            severity: 'success'
                        },
                        style: {
                            backgroundColor: green[100],
                            border: `1px solid ${alpha(green[300], 0.5)}`,
                            '& .MuiAlert-icon': {
                                color: green[500]
                            },
                            '& .MuiButton-text': {
                                '&:hover': {
                                    backgroundColor: alpha(green[300], 0.5)
                                }
                            },
                            '& .MuiButton-colorInherit': {
                                backgroundColor: alpha(green[200], 0.5),
                                '&:hover': {
                                    backgroundColor: alpha(green[300], 0.5)
                                }
                            }
                        }
                    },
                    {
                        props: {
                            severity: 'error'
                        },
                        style: {
                            backgroundColor: red[50],
                            border: `1px solid ${alpha(red[100], 0.5)}`,
                            '& .MuiAlert-icon': {
                                color: red[500]
                            },
                            '& .MuiButton-text': {
                                '&:hover': {
                                    backgroundColor: alpha(red[100], 0.5)
                                }
                            },
                            '& .MuiButton-colorInherit': {
                                backgroundColor: alpha(red[200], 0.5),
                                '&:hover': {
                                    backgroundColor: alpha(red[300], 0.5)
                                }
                            }
                        }
                    }
                ]
            })
        }
    },
    MuiDialog: {
        styleOverrides: {
            root: ({theme}) => ({
                '& .MuiDialog-paper': {
                    borderRadius: '10px',
                    border: '1px solid',
                    borderColor: (theme.vars || theme).palette.divider
                }
            })
        }
    },
    MuiDialogContent: {
        styleOverrides: {
            root: ({theme}) => ({
                backgroundColor: (theme.vars || theme).palette.background.default
            })
        }
    },
    MuiLinearProgress: {
        styleOverrides: {
            root: () => ({
                margin: 5,
                height: 8,
                borderRadius: 8,
                backgroundColor: gray[200]
            })
        }
    }
};
