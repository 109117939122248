import React from 'react';

import SelectField from './SelectField';

function generateInchValues(start, stop) {
    const values = [];
    const step = 0.125; // 1/8 inch in decimal
    for (let value = start; value <= stop; value += step) {
        const whole = Math.floor(value); // Whole number part
        const fraction = Math.round((value - whole) * 8); // Fractional part as eighths

        let label;
        if (fraction === 0) {
            label = `${whole}"`; // Whole number only
        } else if (whole === 0) {
            label = `${fraction}/8"`; // Fraction only (less than 1 inch)
        } else {
            label = `${whole} ${fraction}/8"`; // Whole number and fraction
        }

        // Simplify fractions like 2/8 to 1/4 or 4/8 to 1/2
        label = label
            .replace('2/8', '1/4')
            .replace('4/8', '1/2')
            .replace('6/8', '3/4')
            .replace('8/8', `${whole + 1}"`);

        values.push({label, value: parseFloat(value.toFixed(3))});
    }
    
    return values;
}

export default function(props) {
    const {start = 0.125, stop = 2, ...rest} = props;

    return (
        <SelectField
            {...rest}
            options={generateInchValues(start, stop)}
        />
    )
}