import React, {useState, useCallback, useContext, useEffect} from 'react';
import {Button, Stack, Divider, IconButton, Paper, Grid2 as Grid, Alert, Chip, Box, Checkbox, FormControlLabel, useMediaQuery, Typography, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {get, isUndefined} from 'lodash';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {doc, updateDoc, arrayRemove} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {useTheme} from '@mui/material/styles';
import moment from 'moment';
import {httpsCallable} from 'firebase/functions';
import DeleteIcon from '@mui/icons-material/Delete';
import HideImageIcon from '@mui/icons-material/HideImage';
import UploadIcon from '@mui/icons-material/Upload';
import SyncIcon from '@mui/icons-material/Sync';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {thumbnailPlugin} from '@react-pdf-viewer/thumbnail';
import {ref, getDownloadURL} from 'firebase/storage';

import {db, functions, storage} from '../firebase';
import {UserContext} from '../contexts/User';
import {Ranks, SkillStages, DriverOperatorSkills, getSkillLabel, uploadFile, ensureJSDates} from '../data/utils';

import UserAvatar from '../components/UserAvatar';
import ResponsiveDialog from '../components/ResponsiveDialog';

import ToggleButtonField from '../form/ToggleButtonField';
import TextField from '../form/TextField';
import SelectField from '../form/SelectField';
import DatePickerField from '../form/DatePickerField';

const compactObject = obj => {
    return JSON.parse(JSON.stringify(obj, (key, value) => {
        return (value === null ? undefined : value);
    }));
};

export const pageThumbnailPlugin = props => {
    const {PageThumbnail} = props;

    return {
        renderViewer: renderProps => {
            let {slot} = renderProps;

            slot.children = PageThumbnail;

            // Reset the sub slot
            slot.subSlot.attrs = {};
            slot.subSlot.children = <></>;

            return slot;
        }
    };
};

const PDFThumbnail = ({url}) => {
    const thumbnailPluginInstance = thumbnailPlugin();
    const {Cover} = thumbnailPluginInstance;
    const pageThumbnailPluginInstance = pageThumbnailPlugin({
        PageThumbnail: <Cover getPageIndex={() => 0} />
    });

    return (
        <Worker workerUrl={'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'}>
            <Viewer fileUrl={url} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} />
        </Worker>
    );
};

const File = ({uid, file, editable, ...rest}) => {
    const {filePath, thumbnailPath, grouping, mimeType, storage: outsideStorage, uploadedAt} = file;
    const isPdf = mimeType === 'application/pdf';
    const canSync = outsideStorage === 'googledrive' && !filePath;

    const [deleting, setDeleting] = useState(false);
    const [url, setUrl] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const syncSkillFileFromDriveToStorage = httpsCallable(functions, 'syncSkillFileFromDriveToStorage');
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    const fetchUrls = useCallback(async() => {
        if (!filePath) {
            return;
        }
        
        try {
            const url = await getDownloadURL(ref(storage, filePath));
            setUrl(url)

            if (thumbnailPath) {
                const thumbnailUrl = await getDownloadURL(ref(storage, thumbnailPath));
                setThumbnailUrl(thumbnailUrl);
            }
        } catch(e) {
            console.warn(e);
        }
    }, [filePath]);

    useEffect(() => {
        fetchUrls();
    }, [filePath]);

    const handleDownload = useCallback(async() => {
        if (!url) {
            await fetchUrls();
        }

        window.open(url, '_blank');
    }, [url]);

    const handleSync = useCallback(async e => {
        e.stopPropagation();

        if (deleting) {
            return;
        }

        try {
            await syncSkillFileFromDriveToStorage({uid, grouping});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, []);

    const handleDelete = useCallback(async e => {
        e.stopPropagation();

        if (deleting) {
            return;
        }

        const onDelete = async() => {
            try {
                setDeleting(true);

                await updateDoc(doc(db, 'users', uid), ensureJSDates({
                    files: arrayRemove(file)
                }));
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this file?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete(file);
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [closeSnackbar, enqueueSnackbar, uid]);
    
    return (
        <Grid size={{xs: 6, sm: 4, md: 3}} {...rest}>
            <Paper sx={{aspectRatio: '3/4', p: 1, display: 'flex', alignItems: 'stretch', overflow: 'hidden'}}>
                <Stack
                    spacing={1}
                    sx={{
                        width: '100%',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch'
                    }}
                >
                    <Box
                        onClick={handleDownload}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            flex: 1,
                            borderRadius: 0.5,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundColor: 'grey.200',
                            overflow: 'hidden',
                            ...thumbnailUrl && {backgroundImage: `url(${thumbnailUrl})`}
                        }}
                    >
                        {!thumbnailUrl && !isPdf && !url && <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />}
                        {isPdf && url && <PDFThumbnail url={url} />}
                        {editable && (
                            <Stack sx={{position: 'absolute', top: 8, right: 8}} direction="row" spacing={1}>
                                {canSync && (
                                    <IconButton onClick={handleSync}>
                                        <SyncIcon />
                                    </IconButton>
                                )}
                                <IconButton onClick={handleDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        )}
                    </Box>
                    {uploadedAt && (
                        <Typography component="div" variant="caption" color="text.secondary" sx={{textAlign: 'center'}}>Uploaded {moment(uploadedAt).format(dateFormatLong)}</Typography>
                    )}
                </Stack>
            </Paper>
        </Grid>
    );
}

const Files = ({uid, editable = false, files = []}) => {
    return (
        <>
            {(editable || files.length > 0) && (
                <Typography variant="h6" sx={{mt: 2}}>Files</Typography>
            )}

            <Grid container spacing={1}>
                {files.length === 0 && (
                    <Typography variant="body2" sx={{mt: 1}}>No files uploaded</Typography>
                )}
                {files.map((file, index) => (
                    <File key={`file-${index}`} uid={uid} file={file} editable={editable} />
                ))}
            </Grid>
        </>
    );
};

const MemberTraining = props => {
    const {open, onClose, field, isLevel, editable = false, member = {}} = props;
    const {uid, fullName, role, files = []} = member;
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const {enqueueSnackbar} = useSnackbar();
    // const deleteSkillFile = httpsCallable(functions, 'deleteSkillFile');
    const isDriverOperator = field && ['exterior.apparatusDriverOperator'].includes(field.replace('skills.', ''));

    const rawValue = get(member, field, 'INCOMPLETE');
    const value = typeof rawValue === 'string' ? {status: rawValue} : rawValue || {};

    const skillFiles = files.filter(file => file.grouping === field);

    const {
        status: rawStatus,
        driveFileId: rawDriveFileId,
        uploadedDate: rawUploadedDate,
        scheduledDate: rawScheduledDate,
        completedDate: rawCompletedDate,
        issueDate: rawIssueDate,
        expiryDate: rawExpiryDate,
        licenceType = '',
        licenceNumber,
        redcrossEmail
    } = value;

    const defaultValues = {
        status: rawStatus,
        driveFileId: rawDriveFileId,
        uploadedDate: rawUploadedDate && moment(rawUploadedDate.toDate ? rawUploadedDate.toDate() : rawUploadedDate),
        scheduledDate: rawScheduledDate && moment(rawScheduledDate.toDate ? rawScheduledDate.toDate() : rawScheduledDate),
        completedDate: rawCompletedDate && moment(rawCompletedDate.toDate ? rawCompletedDate.toDate() : rawCompletedDate),
        issueDate: rawIssueDate && moment(rawIssueDate.toDate ? rawIssueDate.toDate() : rawIssueDate),
        expiryDate: rawExpiryDate && moment(rawExpiryDate.toDate ? rawExpiryDate.toDate() : rawExpiryDate),
        licenceType,
        licenceNumber,
        redcrossEmail
    };

    if (isDriverOperator) {
        for (const driverSkill of Object.keys(DriverOperatorSkills)) {
            const key = `driverOperatorSkill${driverSkill}`;
            const {completedDate: rawCompletedDate} = get(value, key, {});
            const completedDate = rawCompletedDate && moment(rawCompletedDate.toDate ? rawCompletedDate.toDate() : rawCompletedDate);
            
            defaultValues[key] = {completedDate}
        }
    }

    let hasHigherLevel = false;
    if (isLevel) {
        if (defaultValues.completedDate && !rawStatus) {
            defaultValues.status = 'COMPLETE';
        } else {
            defaultValues.status = rawStatus || 'INCOMPLETE';
        }

        if (field === 'skills.exterior' || field === 'skills.interior') {
            const hasInterior = get(member, 'skills.interior.status') === 'COMPLETE';
            const hasFullService = get(member, 'skills.fullService.status') === 'COMPLETE';

            if (hasFullService) {
                hasHigherLevel = 'This member has completed Full Service';
            } else if (hasInterior && field !== 'skills.interior') {
                hasHigherLevel = 'This member has completed Interior';
            }
        }
    }

    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {handleSubmit, watch, setValue} = methods;

    const status = watch('status');
    const hasExpiryDate = !!watch('expiryDate');
    const showScheduledDate = ['NEEDED', 'SCHEDULED'].includes(status);
    const hasFields = field && status && !['INCOMPLETE', 'INPROGRESS'].includes(status);
    const isCompleted = field && status === 'COMPLETE';
    const isFR = field && status === 'COMPLETE' && ['exterior.fr3'].includes(field.replace('skills.', ''));
    const {levelLabel, skillLabel, hasExpiry} = getSkillLabel(field);

    const {currentUser} = useContext(UserContext);
    const dateFormatShort = get(currentUser, 'settings.dateFormatShort') || 'MMMM YYYY';

    const driverOperatorSkills = Object.keys(DriverOperatorSkills).map(driverSkill => {
        const key = `driverOperatorSkill${driverSkill}`;
        return watch(`${key}.completedDate`);
    });

    const handleFileUpload = useCallback(async file => {
        if (!file || !uid) {
            return;
        }

        setUploading(true);

        try {
            await uploadFile(`users/${uid}`, file, field);

            enqueueSnackbar('File uploaded successfully!', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e, {variant: 'error'});
        }

        setUploading(false);
        setLoading(false);
    }, [uid, enqueueSnackbar, field, setUploading, setValue]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {status, scheduledDate, completedDate, issueDate, expiryDate, licenceType, licenceNumber, redcrossEmail, driveFileId, uploadedDate, ...rest} = data;
        
        try {
            let newData;

            if (isLevel) {
                newData = {};

                if (!isUndefined(completedDate)) {
                    newData[`${field}.completedDate`] = completedDate;
                }

                if (driveFileId) {
                    newData[`${field}.driveFileId`] = driveFileId;
                }

                if (uploadedDate) {
                    newData[`${field}.uploadedDate`] = uploadedDate;
                }

                if (status) {
                    newData[`${field}.status`] = status;
                }
            } else {
                const newFieldData = {
                    status,
                    ...(showScheduledDate && scheduledDate && {scheduledDate}),
                    ...(driveFileId && {driveFileId}),
                    ...(uploadedDate && {uploadedDate}),
                    ...(expiryDate && {expiryDate}),
                    ...(isCompleted && {
                        ...(completedDate && {completedDate})
                    }),
                    ...(isFR && {
                        ...(issueDate && {issueDate}),
                        ...(licenceType && {licenceType}),
                        ...(licenceNumber && {licenceNumber}),
                        ...(redcrossEmail && {redcrossEmail})
                    }),
                    ...(isDriverOperator && rest && compactObject(rest))
                };

                newData = {
                    [field]: newFieldData
                };
            }

            if (!newData) {
                return;
            }

            const ref = doc(db, 'users', uid);
            await updateDoc(ref, ensureJSDates(newData));

            onClose();
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [onClose, db, enqueueSnackbar, field, showScheduledDate, isFR, isCompleted, uid, isLevel, isDriverOperator]);

    const showExpiryDate = hasExpiryDate || hasExpiry;

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={onClose}
                maxWidth="lg"
                disableEscapeKeyDown={loading}
            >
                <DialogTitle sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <UserAvatar user={member} sx={{mr: 2, width: 50, height: 50}} />
                        <Box sx={{flex: 1}}>
                            {fullName}
                            {role && <Typography variant="subtitle1">{Ranks[role]}</Typography>}
                        </Box>
                        <Chip label={isLevel ? levelLabel : skillLabel} />
                    </Box>

                    {hasHigherLevel && (
                        <Alert severity="success" sx={{flex: 1, mt: 2}}>
                            {hasHigherLevel}
                        </Alert>
                    )}
                </DialogTitle>
                <DialogContent dividers>
                    {!isDriverOperator && (
                        <Box sx={{mb: 2, display: 'flex', flexDirection: 'column'}}>
                            <ToggleButtonField
                                name="status"
                                disabled={!editable}
                                label="Status"
                                exclusive
                                fullWidth
                                size={isMedium ? 'small' : 'medium'}
                                orientation={isSmall ? 'vertical' : 'horizontal'}
                                options={Object.keys(SkillStages).map(value => {
                                    const {label, color, icon} = SkillStages[value];
                                    return {
                                        value,
                                        label: (
                                            <>
                                                {(isSmall || !isMedium) && icon}
                                                <Box sx={{ml: 1}}>
                                                    {label}
                                                </Box>
                                            </>
                                        ),
                                        color
                                    };
                                })}
                            />
                        </Box>
                    )}

                    {(hasFields || isDriverOperator) && (
                        <Grid container spacing={2} sx={{mb: 2}}>
                            {showScheduledDate && (
                                <Grid size={{xs: 12, sm: 6, md: 4}}>
                                    <DatePickerField
                                        fullWidth
                                        views={status === 'NEEDED' ? ['year'] : ['year', 'month']}
                                        format={status === 'NEEDED' ? 'YYYY' : dateFormatShort}
                                        label={status === 'NEEDED' ? 'Target Date' : 'Scheduled Date'}
                                        name="scheduledDate"
                                        disabled={!editable}
                                    />
                                </Grid>
                            )}

                            {isDriverOperator && (
                                <Grid size={{xs: 12}}>
                                    <Stack divider={<Divider flexItem />} spacing={1}>
                                        {Object.keys(DriverOperatorSkills).map((driverSkill, index) => {
                                            const key = `driverOperatorSkill${driverSkill}`;

                                            return (
                                                <Stack key={key} direction="row">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={!!driverOperatorSkills[index]}
                                                                disabled={!editable}
                                                                onChange={() => setValue(`${key}.completedDate`, driverOperatorSkills[index] ? null : moment())}
                                                            />
                                                        }
                                                        label={`#${driverSkill}: ${DriverOperatorSkills[driverSkill]}`}
                                                        sx={{flex: 1}}
                                                    />

                                                    <DatePickerField
                                                        size="small"
                                                        sx={{width: 180}}
                                                        views={['year', 'month']}
                                                        format={dateFormatShort}
                                                        label="Completed Date"
                                                        name={`${key}.completedDate`}
                                                        disabled={!editable}
                                                    />
                                                </Stack>
                                            );
                                        })}
                                    </Stack>
                                </Grid>
                            )}

                            {isCompleted && !isFR && !isDriverOperator && (
                                <Grid size={{sm: 12, md: 6}}>
                                    <DatePickerField
                                        fullWidth
                                        views={['year', 'month']}
                                        format={dateFormatShort}
                                        label="Completed Date"
                                        name="completedDate"
                                        disabled={!editable}
                                    />
                                </Grid>
                            )}

                            {showExpiryDate && (
                                <Grid size={{sm: 12, md: 6}}>
                                    <DatePickerField
                                        fullWidth
                                        label="Expiry Date"
                                        name="expiryDate"
                                        disabled={!editable}
                                    />
                                </Grid>
                            )}

                            {isCompleted && isFR && (
                                <>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <DatePickerField
                                            fullWidth
                                            label="Issue Date"
                                            name="issueDate"
                                            disabled={!editable}
                                        />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <TextField
                                            fullWidth
                                            label="Licence #"
                                            name="licenceNumber"
                                            disabled={!editable}
                                        />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <DatePickerField
                                            fullWidth
                                            label="Expiry Date"
                                            name="expiryDate"
                                            disabled={!editable}
                                        />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <TextField
                                            fullWidth
                                            label="Red Cross Email"
                                            name="redcrossEmail"
                                            disabled={!editable}
                                        />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <SelectField
                                            fullWidth
                                            label="Licence Type"
                                            name="licenceType"
                                            disabled={loading || !editable}
                                            allowBlank
                                            options={[
                                                {value: 'EMAFR', label: 'FR'},
                                                {value: 'EMAFRWSCOPE', label: 'FR (without scope update)'},
                                                {value: 'EMR', label: 'EMR'},
                                                {value: 'EMRWSCOPE', label: 'EMR (without scope update)'},
                                                {value: 'PCP', label: 'PCP'},
                                                {value: 'PCPWSCOPE', label: 'PCP (without scope update)'}
                                            ]}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}

                    <Files uid={uid} editable={editable} files={skillFiles} />

                    {editable && (
                        <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                            <LoadingButton loading={uploading} variant="contained" component="label" startIcon={<UploadIcon />}>
                                Upload File
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => {
                                        const file = e.target.files[0];
                                        handleFileUpload(file);
                                        e.target.value = null;
                                    }}
                                />
                            </LoadingButton>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={onClose}>{editable ? 'Cancel' : 'Close'}</Button>
                    {editable && <LoadingButton loading={loading} variant="contained" disabled={loading} onClick={handleSubmit(onSubmit)}>Save Skill</LoadingButton>}
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
}
export default MemberTraining;