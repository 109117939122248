import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useTheme} from '@mui/material/styles';
import {Skeleton, Button, Box, Card, CardMedia, Typography, Grid2 as Grid, useMediaQuery} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {get, sortBy, chunk} from 'lodash';
import moment from 'moment';
import {collection, query, orderBy, where, addDoc, limit} from 'firebase/firestore';
import WarningIcon from '@mui/icons-material/Warning';
import HideImageIcon from '@mui/icons-material/HideImage';
import {ref, getDownloadURL} from 'firebase/storage';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FireTruckIcon from '@mui/icons-material/FireTruck';

import {UserContext} from '../../contexts/User';
import {db, storage} from '../../firebase';

import {getCollection, populateKeyFromCollection} from '../../data/utils';

const ApparatusCard = props => {
    const {apparatus} = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const {uid, tag, image, latestCheck} = apparatus;
    const {uid: latestCheckUid, active, createdAt, type} = latestCheck || {};
    const isMoreThanAWeek = latestCheck && moment().diff(createdAt, 'days') > 7;
    const isActive = latestCheck && active;
    const severity = isActive ? 'warning' : isMoreThanAWeek ? 'error' : 'info';

    const handleContinueTruckCheck = useCallback(e => {
        e.stopPropagation();
        
        navigate(`/apparatus/${uid}/${type}/${latestCheckUid}`);
    }, [latestCheck]);

    const handleStartTruckCheck = useCallback(async e => {
        e.stopPropagation();

        setLoading(true);

        try {
            const ref = collection(db, 'checks');
            const q = query(ref, where('apparatus', '==', uid), where('type', '==', 'weekly'), orderBy('createdAt', 'desc'), limit(1));
            const docs = await getCollection(db, q);

            const [record] = docs || [];
            if (!record) {
                return;
            }

            const {checks = []} = record;
            
            const data = {
                type,
                loggedChecks: checks,
                createdAt: new Date(),
                active: true,
                users: []
            };

            const checkRef = collection(db, 'apparatus', uid, 'checks');
            const checkRecordRaw = await addDoc(checkRef, data);

            navigate(`/apparatus/${uid}/${type}/${checkRecordRaw.id}`);
        } catch(e) {
            //
        }

        setLoading(false);
    }, [uid]);
    
    const onClick = useCallback(e => {
        const {metaKey} = e;

        const url = `/apparatus/${uid}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate, uid]);

    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url)
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url)
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [image]);

    return (
        <Card variant="outlined" severity={severity} sx={{p: 1}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant="h4" component="div" sx={{flex: 1, lineHeight: 1, pb: 0.5}}>
                    {tag}
                </Typography>
                {isMoreThanAWeek && (
                    <WarningIcon sx={{ml: 1}} color={active ? 'warning' : 'error'} />
                )}
            </Box>
            
            <Typography variant="overline" component="div" sx={{lineHeight: 1, pb: 1}}>
                <Box component="span" sx={theme => ({color: (theme.vars || theme).palette.text.secondary})}>Last Check:</Box> <strong>{latestCheck ? (active ? 'ACTIVE' : createdAt.fromNow()) : 'N/A'}</strong>
            </Typography>

            <CardMedia
                sx={{aspectRatio: '16/9', borderRadius: 0.5, backgroundColor: 'background.paper', cursor: 'pointer'}}
                image={imageUrl}
                title={tag}
                onClick={onClick}
            >
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'text.secondary', opacity: 0.5}}>
                    {!image && (
                        <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />
                    )}
                </Box>
            </CardMedia>
            
            {active && (
                <Box sx={{display: 'flex', mt: 1}}>
                    <Button sx={{flex: 1}} size="small" variant="contained" color={severity} onClick={handleContinueTruckCheck}>
                        Continue Truck Check
                    </Button>
                </Box>
            )}
        </Card>
    );
}

const ApparatusSkeleton = () => (
    <Grid size={{xs: 12, sm: 6, lg: 4, xl: 4}}>
        <Skeleton variant="rectangular" height={240}>
            
        </Skeleton>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Skeleton width="20%" height={64} />
            <Skeleton width="40%" height={40} />
        </Box>
    </Grid>
);

const Apparatuses = () => {
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const {station, isStation = false, isAdmin = false} = currentUser;
    const stationUid = typeof station === 'string' ? station : get(station, 'uid');

    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                let docs = await getCollection(db, 'apparatus');
                docs = await populateKeyFromCollection(db, docs, 'station', 'stations');

                if ((!isAdmin && station) || isStation) {
                    docs = docs.filter(apparatus => {
                        return get(apparatus, 'station.uid') === stationUid;
                    });
                }

                docs = await Promise.all(docs.map(async apparatus => {
                    const {id} = apparatus;
                    
                    const ref = collection(db, 'apparatus', id, 'checks');
                    const q = query(ref, where('type', '==', 'weekly'), orderBy('createdAt', 'desc'), limit(1));
                    const [check] = await getCollection(db, q);

                    return {
                        ...apparatus,
                        latestCheck: check
                    };
                }));

                docs = sortBy(docs, ['station.name', 'tag']);

                if (isSubscribed) {
                    setDocs(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, station, isAdmin]);

    const chunked = chunk(docs || [], isSmall ? 1 : 2);

    return (
        <>
            <Grid container spacing={2}>
                {loading ? (
                    <>
                        <ApparatusSkeleton />
                        <ApparatusSkeleton />
                        <ApparatusSkeleton />
                        <ApparatusSkeleton />
                    </>
                ) : chunked.map(docs => {
                    return docs.map(doc => (
                        <Grid size={{xs: 12, sm: 6, lg: 4, xl: 4}} key={doc.uid}>
                            <ApparatusCard apparatus={doc} key={doc.uid} />
                        </Grid>
                    ));
                })}
            </Grid>

            {isAdmin && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button component={RouterLink} variant="contained" to="/apparatus/new">Add Apparatus</Button>
                </Box>
            )}
        </>
    );
};

export default Apparatuses;