import {alpha} from '@mui/material/styles';
import {buttonBaseClasses} from '@mui/material/ButtonBase';
import {dividerClasses} from '@mui/material/Divider';
import {menuItemClasses} from '@mui/material/MenuItem';
import {tabClasses} from '@mui/material/Tab';
import {gray, brand} from '../themePrimitives';

export const navigationCustomizations = {
    MuiMenuItem: {
        styleOverrides: {
            root: ({theme}) => ({
                borderRadius: (theme.vars || theme).shape.borderRadius,
                padding: '6px 8px',
                [`&.${menuItemClasses.focusVisible}`]: {
                    backgroundColor: 'transparent'
                },
                [`&.${menuItemClasses.selected}`]: {
                    [`&.${menuItemClasses.focusVisible}`]: {
                        backgroundColor: alpha(theme.palette.action.selected, 0.3)
                    }
                }
            })
        }
    },
    MuiMenu: {
        styleOverrides: {
            list: {
                gap: '1px',
                [`&.${dividerClasses.root}`]: {
                    margin: '0 -8px'
                },
                '.MuiPopover-paper & .MuiMenuItem-gutters': {
                    marginRight: 8
                }
            },
            paper: ({theme}) => ({
                marginTop: '4px',
                paddingLeft: 0,
                paddingRight: 0,
                borderRadius: (theme.vars || theme).shape.borderRadius,
                border: `1px solid ${(theme.vars || theme).palette.divider}`,
                backgroundImage: 'none',
                background: 'hsl(0, 0%, 100%)',
                boxShadow: 'hsla(220, 30%, 5%, 0.07) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.07) 0px 8px 16px -5px',
                [`& .${buttonBaseClasses.root}`]: {
                    '&.Mui-selected': {
                        backgroundColor: alpha(theme.palette.action.selected, 0.3)
                    }
                }
            })
        }
    },
    MuiAutocomplete: {
        styleOverrides: {
            paper: {
                background: 'transparent'
            },
            listbox: ({theme}) => ({
                gap: '0px',
                marginTop: '4px',
                paddingLeft: '6px',
                paddingRight: '6px',
                borderRadius: (theme.vars || theme).shape.borderRadius,
                border: `1px solid ${(theme.vars || theme).palette.divider}`,
                backgroundImage: 'none',
                background: 'hsl(0, 0%, 100%)',
                boxShadow: 'hsla(220, 30%, 5%, 0.07) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.07) 0px 8px 16px -5px',
                [`& .${buttonBaseClasses.root}`]: {
                    '&.Mui-selected': {
                        backgroundColor: alpha(theme.palette.action.selected, 0.3),
                        borderRadius: (theme.vars || theme).shape.borderRadius
                    }
                }
            }),
            groupUl: ({theme}) => ({
                marginTop: 1,
                marginBottom: 1,
                '& li': {
                    borderRadius: (theme.vars || theme).shape.borderRadius
                }
            })
        }
    },
    MuiSelect: {
        // defaultProps: {
        //     IconComponent: forwardRef((props, ref) => (
        //         <UnfoldMoreRoundedIcon fontSize="small" {...props} ref={ref} />
        //     ))
        // },
        styleOverrides: {
            root: () => ({
                // borderRadius: (theme.vars || theme).shape.borderRadius,
                // border: '1px solid',
                // borderColor: gray[200],
                // backgroundColor: (theme.vars || theme).palette.background.paper,
                // boxShadow: `inset 0 1px 0 1px hsla(220, 0%, 100%, 0.6), inset 0 -1px 0 1px hsla(220, 35%, 90%, 0.5)`,
                // '&:hover': {
                //   borderColor: gray[300],
                //   backgroundColor: (theme.vars || theme).palette.background.paper,
                //   boxShadow: 'none',
                // },
                // [`&.${selectClasses.focused}`]: {
                //   outlineOffset: 0,
                //   borderColor: gray[400],
                // },
                // '&:before, &:after': {
                //   display: 'none',
                // }
            })
            // select: ({theme}) => ({
            //     display: 'flex',
            //     alignItems: 'center'
            // }),
            // menu: ({theme}) => ({
            //     marginTop: '4px',
            //     backgroundColor: 'red'
            // })
        }
    },
    MuiLink: {
        defaultProps: {
            underline: 'none'
        },
        styleOverrides: {
            root: ({theme}) => ({
                color: (theme.vars || theme).palette.text.primary,
                fontWeight: 500,
                position: 'relative',
                textDecoration: 'none',
                width: 'fit-content',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '1px',
                    bottom: 0,
                    left: 0,
                    backgroundColor: (theme.vars || theme).palette.text.secondary,
                    opacity: 0.3,
                    transition: 'width 0.3s ease, opacity 0.3s ease'
                },
                '&:hover::before': {
                    width: 0
                },
                '&:focus-visible': {
                    outline: `3px solid ${alpha(brand[500], 0.5)}`,
                    outlineOffset: '4px',
                    borderRadius: '2px'
                }
            })
        }
    },
    MuiDrawer: {
        styleOverrides: {
            paper: ({theme}) => ({
                backgroundColor: (theme.vars || theme).palette.background.default
            })
        }
    },
    MuiPaginationItem: {
        styleOverrides: {
            root: ({theme}) => ({
                '&.Mui-selected': {
                    color: '#fff',
                    backgroundColor: (theme.vars || theme).palette.grey[900]
                }
            })
        }
    },
    MuiTabs: {
        styleOverrides: {
            root: {minHeight: 'fit-content'},
            indicator: ({theme}) => ({
                backgroundColor: (theme.vars || theme).palette.grey[800]
            })
        }
    },
    MuiTabPanel: {
        styleOverrides: {
            root: {
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: ({theme}) => ({
                padding: '6px 8px',
                marginBottom: '8px',
                textTransform: 'none',
                minWidth: 'fit-content',
                minHeight: 'fit-content',
                color: (theme.vars || theme).palette.text.secondary,
                borderRadius: (theme.vars || theme).shape.borderRadius,
                border: '1px solid',
                borderColor: 'transparent',
                ':hover': {
                    color: (theme.vars || theme).palette.text.primary,
                    backgroundColor: gray[100],
                    borderColor: gray[200]
                },
                [`&.${tabClasses.selected}`]: {
                    color: gray[900]
                }
            })
        }
    },
    MuiStepConnector: {
        styleOverrides: {
            line: ({theme}) => ({
                borderTop: '1px solid',
                borderColor: (theme.vars || theme).palette.divider,
                flex: 1,
                borderRadius: '99px'
            })
        }
    },
    MuiStepIcon: {
        styleOverrides: {
            root: ({theme}) => ({
                color: 'transparent',
                border: `1px solid ${gray[400]}`,
                width: 12,
                height: 12,
                borderRadius: '50%',
                '& text': {
                    display: 'none'
                },
                '&.Mui-active': {
                    border: 'none',
                    color: (theme.vars || theme).palette.primary.main
                },
                '&.Mui-completed': {
                    border: 'none',
                    color: (theme.vars || theme).palette.success.main
                },
                variants: [
                    {
                        props: {completed: true},
                        style: {
                            width: 12,
                            height: 12
                        }
                    }
                ]
            })
        }
    },
    MuiStepLabel: {
        styleOverrides: {
            label: () => ({
                '&.Mui-completed': {
                    opacity: 0.6
                }
            })
        }
    }
};
