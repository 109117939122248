import React from 'react';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import {Box} from '@mui/material';

import StatefulDataGrid from './StatefulDataGrid';

const Searchable = props => {
    const {slotProps, stateId, rows, ...rest} = props;
    const Component = stateId ? StatefulDataGrid : DataGridPro;

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{display: 'flex', flexDirection: 'column', minHeight: rows.length === 0 ? 250 : 'auto'}}>
                <Component
                    stateId={stateId}
                    disableColumnFilter
                    disableDensitySelector
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                variant: 'outlined',
                                size: 'small'
                            }
                        },
                        ...slotProps
                    }}
                    rows={rows}
                    {...rest}
                />
            </Box>
        </Box>
    );
};

export default Searchable;