import React, {useState, useMemo, useEffect, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Divider, Stack, Button, IconButton, Box, Typography, Skeleton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {get, isString, omit} from 'lodash';
import {useParams} from 'react-router-dom';
import {doc, updateDoc, deleteDoc, onSnapshot} from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import * as MaterialIcons from '@mui/icons-material';
import {ref, getDownloadURL} from 'firebase/storage';

import * as Icons from '../components/Icons';

import {db, storage} from '../firebase';
import {UserContext} from '../contexts/User';

import {processRawDoc, getCollectionDoc, verifyOfficer, ensureJSDates, uploadImage} from '../data/utils';

import useDocumentTitle from '../hooks/useDocumentTitle';

import CategoryDialog from './gear/CategoryDialog';
import CategoriesGrid from './gear/CategoriesGrid';
import TasksGrid from './gear/TasksGrid';
import ItemsGrid from './gear/ItemsGrid';

import Placement from './gear/collection/Placement';

import {useItems} from './gear/ItemsGrid';

export default function GearCollection() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {items} = useItems();

    const {name, icon, apparatus, image, additionalFields = []} = row || {};
    const Icon = !loading && icon && icon.value && (Icons[icon.value] || MaterialIcons[icon.value]);

    const refArgs = useMemo(() => {
        let refArgs = [db];

        Object.entries(params).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        return refArgs;
    }, [db, params]);

    const deleteUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        if (parts.length === 2) {
            return '/gear';
        }

        return `/${parts.slice(0, -2).join('/')}`;
    }, [params]);
    
    useEffect(() => {
        setLoading(true);

        const ref = doc(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            if (!snapshot.exists()) {
                navigate(deleteUrl);
                return;
            }

            const row = processRawDoc(snapshot);
            if (!row) {
                return;
            }

            const {icon, apparatus: rawApparatus, ...rest} = row;
            const apparatusUid = isString(rawApparatus) ? rawApparatus : get(rawApparatus, 'uid');
            let apparatus = await getCollectionDoc(db, 'apparatus', apparatusUid);

            setRow({
                ...rest,
                icon: icon ? {value: icon, label: icon} : null,
                apparatus
            });

            setLoading(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs, deleteUrl, navigate]);

    useDocumentTitle(name);

    const onUpdate = async data => {
        setLoading(true);

        const {icon, imageFile, apparatus, ...rest} = omit(data, 'uid');

        let image;
        if (imageFile) {
            const path = refArgs.slice(1).join('/');
            image = await uploadImage(path, imageFile);
        } else if (imageFile === null) {
            image = null;
        }

        const toUpdate = ensureJSDates({
            ...rest,
            image
        });

        toUpdate.icon = icon ? (icon.value || icon) : null;

        if (apparatus) {
            toUpdate.apparatus = typeof apparatus === 'string' ? apparatus : apparatus.uid;
        } else {
            toUpdate.apparatus = null;
        }

        if (Object.keys(toUpdate)) {
            await updateDoc(doc(...refArgs), {
                ...toUpdate,
                updatedAt: new Date()
            });

            if (toUpdate.apparatus) {
                toUpdate.apparatus = await getCollectionDoc(db, 'apparatus', toUpdate.apparatus);
            }

            setRow({
                ...toUpdate,
                image,
                ...icon && {icon: {value: icon, label: icon}}
            });
        }

        setLoading(false);

        return true;
    };

    const handleDelete = useCallback(() => {
        const onDelete = async() => {
            setLoading(true);

            const ref = doc(...refArgs);
            await deleteDoc(ref);

            navigate(deleteUrl);
        };

        return new Promise(resolve => {
            enqueueSnackbar(`Are you sure you want to delete ${name}? All information, items and categories will be deleted.`, {
                variant: 'warning',
                action: key => {
                    return (
                        <>
                            <Button onClick={() => {
                                resolve(true);
                                closeSnackbar(key);
                                onDelete();
                            }}>
                                Delete
                            </Button>
                            <Button onClick={() => {
                                resolve(false);
                                closeSnackbar(key);
                            }}>
                                Cancel
                            </Button>
                        </>
                    );
                }
            });
        });
    }, [refArgs, enqueueSnackbar, closeSnackbar, navigate, deleteUrl, name]);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url)
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url)
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };
        
        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    const hasPlacement = imageUrl && items.length > 0 && items.some(item => item.position);

    return (
        <Box>
            {isOfficer && (
                <CategoryDialog onSubmit={onUpdate} onDelete={handleDelete} item={row} open={open} handleClose={() => setOpen(false)} />
            )}

            <Stack direction="row" spacing={1} sx={{mb: 1, alignItems: 'center'}}>
                {imageUrl && (
                    <Box sx={{aspectRatio: '4/3', height: 50, borderRadius: 1, backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}} />
                )}
                {Icon && !imageUrl && (
                    <Icon sx={{fontSize: 50}} />
                )}
                {loading && (
                    <Skeleton variant="rectangular" sx={{height: 50, width: 50, borderRadius: 1}} />
                )}
                <Box sx={{flex: 1}}>
                    <Typography variant="h5" sx={{lineHeight: 1}}>{loading ? <Skeleton /> : name}</Typography>
                    {apparatus && (
                        <Typography variant="caption" color="text.secondary" sx={{lineHeight: 1}}>{loading ? <Skeleton /> : get(apparatus, 'tag')}</Typography>
                    )}
                </Box>
                {isOfficer && (
                    <IconButton disabled={loading} onClick={() => setOpen(true)}>
                        <EditIcon />
                    </IconButton>
                )}
            </Stack>

            <Divider sx={{mb: 2}} />

            <CategoriesGrid />

            {/* <TasksGrid /> */}

            {!loading && additionalFields.length > 0 && (
                <>
                    {hasPlacement && <Placement imageUrl={imageUrl} />}
                    
                    <ItemsGrid />
                </>
            )}
        </Box>
    );
};