import React, {useState, useEffect, useContext} from 'react';
import {Skeleton, TableContainer, Table, TableBody, TableCell, TableRow, Grid2 as Grid, Typography, Card, CardContent, Stack, Chip, Box} from '@mui/material';
import {PieChart, LineChart, ResponsiveChartContainer} from '@mui/x-charts';
import {collection, query, where} from 'firebase/firestore';
import moment from 'moment';
import {get, groupBy, reduce} from 'lodash';

import {db} from '../firebase';
import {UserContext} from '../contexts/User';
import {StationsContext} from '../contexts/Stations';

import {FirefighterRanks, populateUsers, getCollection, colorForIncidentType, IncidentTypes} from '../data/utils';

import Apparatuses from './apparatuses/Grid';

const MemberResponseTableRow = props => {
    const {index, fullName, incidents, ...rest} = props;

    return (
        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}} {...rest}>
            <TableCell component="th" scope="row" sx={{py: 0.5, fontSize: '0.8rem', fontWeight: 'bold', width: 30}}>
                {index}
            </TableCell>
            <TableCell component="th" scope="row" sx={{px: 0, py: 0.5, fontSize: '0.8rem'}}>
                {fullName}
            </TableCell>
            <TableCell align="right" sx={{py: 0.5, display: 'flex', alignItems: 'flex-end', fontSize: '0.8rem'}}>{incidents}</TableCell>
        </TableRow>
    );
};

const StatCard = props => {
    const {title, heading, legend, overlap = false, trend = false, interval, children} = props;

    const labelColors = {
        up: 'success',
        down: 'error',
        neutral: 'default'
    };

    const color = labelColors[trend || 'neutral'];
    const trendValues = {up: '+25%', down: '-25%', neutral: '+5%'};
    return (
        <Card variant="outlined" sx={{height: '100%', flexGrow: 1}}>
            <CardContent sx={{position: 'relative'}}>
                <Box sx={overlap ? {position: 'absolute', top: 16, left: 16} : {}}>
                    {(title || trend || legend) && (
                        <Stack
                            direction="row"
                            sx={{justifyContent: 'space-between', alignItems: 'center'}}
                        >
                            <Typography component="h2" variant="subtitle2">
                                {title}
                            </Typography>
                            {(trend || legend) && <Chip size="small" color={color} label={trendValues[trend] || legend} />}
                        </Stack>
                    )}
                    {(heading || interval) && (
                        <Stack sx={{justifyContent: 'space-between'}}>
                            <Typography variant="h3" component="p">
                                {heading}
                            </Typography>
                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                {interval}
                            </Typography>
                        </Stack>
                    )}
                </Box>
                <Box sx={{width: '100%'}}>
                    {children}
                </Box>
            </CardContent>
        </Card>
    );
};

const IncidentsDaysCard = ({loading, incidents = [], days = 30}) => {
    const filtered = incidents.filter(incident => {
        const {date} = incident;
        return date.isAfter(moment().subtract(days, 'days'));
    });
    const grouped = groupBy(filtered, 'type');

    return (
        <StatCard
            heading={filtered.length}
            title="Incidents"
            interval={`Last ${days} days`}
            overlap
        >
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 10}}>
                <PieChart
                    loading={loading}
                    width={150}
                    height={150}
                    margin={{left: 0, right: 0, top: 0, bottom: 0}}
                    series={[{
                        data: Object.keys(grouped).map(type => ({
                            label: IncidentTypes[type],
                            value: grouped[type].length,
                            color: colorForIncidentType(type)
                        })),
                        highlightScope: {fade: 'global', highlight: 'item'},
                        label: 'Number of Incidents',
                        innerRadius: 20,
                        paddingAngle: 2,
                        cornerRadius: 2
                        // cx: '80%'
                    }]}
                    slotProps={{
                        legend: {
                            hidden: true
                            // labelStyle: {
                            //     fontSize: 10
                            // },
                            // itemMarkWidth: 10,
                            // itemMarkHeight: 3,
                            // markGap: 7,
                            // itemGap: 5
                        }
                    }}
                />
            </Box>
        </StatCard>
    );
}

const IncidentsYearCard = ({loading, incidents = []}) => {
    const incidentsByType = reduce(incidents.reduce((result, doc) => {
        const {type} = doc;

        if (!result[type]) {
            result[type] = {
                value: 0,
                label: IncidentTypes[type],
                type,
                incidents: []
            };
        }

        result[type].value++;
        result[type].incidents.push(doc);

        return result;
    }, {}), (result, value, key) => {
        const {type, incidents} = value || {};
        const groupedByMonth = groupBy(incidents, incident => {
            const {date} = incident;
            return moment(date).format('YYYY-M');
        });

        result.push({
            id: key,
            ...value,
            type,
            groupedByMonth,
            color: colorForIncidentType(type)
        });

        return result;
    }, []).sort((a, b) => {
        return b.value < a.value ? 1 : -1;
    });

    const incidentsByMonth = reduce(incidents.reduce((result, doc) => {
        const {date} = doc;
        const month = moment(date).format('YYYY-M');

        if (!result[month]) {
            result[month] = {
                date,
                value: date,
                label: moment(date).format('MMMM YYYY'),
                incidents: []
            };
        }

        result[month].incidents.push(doc);

        return result;
    }, {}), (result, value, key) => {
        const {incidents} = value || {};
        const groupedByType = groupBy(incidents, 'type');

        result.push({
            id: key,
            ...value,
            groupedByType
        });

        return result;
    }, []);

    return (
        <StatCard heading={incidents.length} title="Incidents" interval="This year" overlap>
            <LineChart
                loading={loading}
                height={150}
                leftAxis={null}
                xAxis={[{
                    scaleType: 'point',
                    data: incidentsByMonth.map(({label}) => label)
                }]}
                series={incidentsByType.map(byType => {
                    const {type, label, groupedByMonth} = byType;
                    const data = incidentsByMonth.map(byMonth => {
                        const {id} = byMonth;

                        const [year, month] = id.split('-');
                        let total = 0;
                        
                        for (let i = 1; i <= parseInt(month); i++) {
                            const grouped = groupedByMonth[`${year}-${i}`] || [];
                            total += grouped.length;
                        }

                        return total;
                    });

                    return {
                        type: 'line',
                        stack: 'total',
                        color: colorForIncidentType(type),
                        label,
                        data,
                        area: true,
                        curve: 'natural',
                        showMark: false
                    }
                })}
                axisHighlight={{
                    x: 'band',
                    y: 'none'
                }}
                slotProps={{
                    legend: {
                        hidden: true
                    }
                }}
                margin={{top: 0, left: 0, right: 0, bottom: 0}}
            />
        </StatCard>
    );
};

const IncidentsResponseCard = ({loading, station, incidents = []}) => {
    const {users} = useContext(UserContext);
    const filtered = incidents.filter(incident => {
        const {date} = incident;
        return date.isAfter(moment().subtract(90, 'days'));
    });

    const attendance = users.reduce((result, user) => {
        const {uid, role, station: userStationUid, deactivated, ...rest} = user;
        if (!FirefighterRanks.includes(role)) {
            return result;
        }

        if (deactivated) {
            return result;
        }
        
        if (station && get(station, 'uid') !== userStationUid) {
            return result;
        }

        const userIncidents = filtered.reduce((result, incident) => {
            const {members = []} = incident;

            if (members.filter(Boolean).find(member => (member.uid === uid || member === uid))) {
                result.push(incident);
            }

            return result;
        }, []);

        result.push({
            uid,
            ...rest,
            incidents: userIncidents
        });

        return result;
    }, []).sort((a, b) => {
        return b.incidents.length < a.incidents.length ? -1 : 1;
    }).slice(0, 5);

    if (!loading && !attendance.length) {
        return null;
    }

    return (
        <StatCard title="Member Response" interval="Last 90 days" legend={station ? `Station ${station.name}` : null}>
            <TableContainer sx={{mt: 1}}>
                <Table size="small">
                    <TableBody>
                        {loading ? Array.from({length: 5}).map((row, index) => (
                            <MemberResponseTableRow
                                key={index}
                                index={index + 1}
                                fullName={<Skeleton variant="text" width={100} />}
                                incidents={<Skeleton variant="text" width={30} />}
                            />
                        )) : attendance.map((row, index) => {
                            const {uid, fullName, incidents} = row;

                            return (
                                <MemberResponseTableRow key={uid} index={index + 1} fullName={fullName} incidents={incidents.length} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </StatCard>
    );
};

const IncidentsCards = () => {
    const [incidents, setIncidents] = useState([]);
    const [loading, setLoading] = useState(false);
    const {stations} = useContext(StationsContext);

    async function fetch() {
        setLoading(true);

        try {
            const startDate = moment().startOf('year').toDate();
            const endDate = moment().endOf('day').toDate();

            const ref = collection(db, 'incidents');
            const q = query(ref, where('date', '>=', startDate), where('date', '<=', endDate));
            let docs = await getCollection(db, q);
            docs = await populateUsers(db, docs);

            setIncidents(docs);
        } catch (e) {
            console.error('Error fetching incidents:', e);
        }

        setLoading(false);
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12, sm: 6}}>
                <IncidentsDaysCard loading={loading} incidents={incidents} days={90} />
            </Grid>
            <Grid size={{xs: 12, sm: 6}}>
                <IncidentsYearCard loading={loading} incidents={incidents} />
            </Grid>
            {stations.map(station => (
                <Grid size={{xs: 12, sm: 6}} key={`dashboard-memberstats-station${station.id}`}>
                    <IncidentsResponseCard loading={loading} incidents={incidents} station={station} />
                </Grid>
            ))}
        </Grid>
    );
};

export default function() {
    return (
        <>
            <Typography variant="h5" gutterBottom>Overview</Typography>
            <IncidentsCards />

            <Typography variant="h5" gutterBottom sx={{mt: 4}}>Apparatus</Typography>
            <Apparatuses />
        </>
    );
};