import {paperClasses} from '@mui/material/Paper';
import {alpha} from '@mui/material/styles';
import {menuItemClasses} from '@mui/material/MenuItem';
import {listItemIconClasses} from '@mui/material/ListItemIcon';
import {iconButtonClasses} from '@mui/material/IconButton';
import {checkboxClasses} from '@mui/material/Checkbox';
import {listClasses} from '@mui/material/List';
import {gridClasses} from '@mui/x-data-grid';
import {tablePaginationClasses} from '@mui/material/TablePagination';
import {gray} from '../themePrimitives';

export const dataGridCustomizations = {
    MuiDataGrid: {
        styleOverrides: {
            root: ({theme}) => ({
                '--DataGrid-overlayHeight': '300px',
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                },
                '& .MuiDataGrid-cell--textLeft': {
                    display: 'flex',
                    alignItems: 'center'
                },
                '& .MuiDataGrid-row--firstVisible .MuiDataGrid-cell': {
                    borderTop: 0
                },
                '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
                    borderBottom: 0
                },
                '& .MuiDataGrid-cell--pinnedLeft, & .MuiDataGrid-withBorderColor': {
                    borderRight: '1px solid',
                    borderColor: (theme.vars || theme).palette.divider
                },
                '& .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor': {
                    borderTop: '1px solid',
                    borderColor: (theme.vars || theme).palette.divider
                },
                overflow: 'clip',
                borderColor: (theme.vars || theme).palette.divider,
                backgroundColor: (theme.vars || theme).palette.background.default,
                [`& .MuiDataGrid-row--borderBottom .${gridClasses.columnHeader}`]: {
                    backgroundColor: (theme.vars || theme).palette.background.paper,
                    borderBottomColor: `${(theme.vars || theme).palette.divider} !important`
                },
                [`& .${gridClasses.footerContainer}`]: {
                    backgroundColor: (theme.vars || theme).palette.background.paper
                },
                [`& .${checkboxClasses.root}`]: {
                    padding: theme.spacing(0.5),
                    '& > svg': {
                        fontSize: '1rem'
                    }
                },
                [`& .${tablePaginationClasses.root}`]: {
                    marginRight: theme.spacing(1),
                    '& .MuiIconButton-root': {
                        maxHeight: 32,
                        maxWidth: 32,
                        '& > svg': {
                            fontSize: '1rem'
                        }
                    }
                }
            }),
            toolbarContainer: ({theme}) => ({
                borderBottom: '1px solid',
                borderBottomColor: (theme.vars || theme).palette.divider,
                '& .MuiButton-root': {
                    marginBottom: 'calc(0.5 * var(--template-spacing))'
                }
            }),
            cell: ({theme}) => ({
                borderTopColor: (theme.vars || theme).palette.divider
            }),
            menu: ({theme}) => ({
                borderRadius: theme.shape.borderRadius,
                backgroundImage: 'none',
                [`& .${paperClasses.root}`]: {
                    border: `1px solid ${(theme.vars || theme).palette.divider}`
                },

                [`& .${menuItemClasses.root}`]: {
                    margin: '0 4px'
                },
                [`& .${listItemIconClasses.root}`]: {
                    marginRight: 0
                },
                [`& .${listClasses.root}`]: {
                    paddingLeft: 0,
                    paddingRight: 0
                }
            }),
            row: ({theme}) => ({
                // '&:last-of-type': {borderBottom: `1px solid ${(theme.vars || theme).palette.divider}`},
                '&:hover': {
                    backgroundColor: (theme.vars || theme).palette.action.hover
                },
                '&.Mui-selected': {
                    background: (theme.vars || theme).palette.action.selected,
                    '&:hover': {
                        backgroundColor: (theme.vars || theme).palette.action.hover
                    }
                }
            }),
            iconButtonContainer: ({theme}) => ({
                [`& .${iconButtonClasses.root}`]: {
                    border: 'none',
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: alpha(theme.palette.action.selected, 0.3)
                    },
                    '&:active': {
                        backgroundColor: gray[200]
                    }
                }
            }),
            menuIconButton: () => ({
                border: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: gray[100]
                },
                '&:active': {
                    backgroundColor: gray[200]
                }
            }),
            filterForm: ({theme}) => ({
                gap: theme.spacing(1),
                alignItems: 'flex-end'
            }),
            columnsManagementHeader: ({theme}) => ({
                paddingRight: theme.spacing(3),
                paddingLeft: theme.spacing(3)
            }),
            columnHeaderTitleContainer: {
                flexGrow: 1,
                justifyContent: 'space-between'
            },
            columnHeaderDraggableContainer: {paddingRight: 2}
        }
    }
};
