import React, {useContext, useState} from 'react';
import {Controller} from 'react-hook-form';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {get} from 'lodash';
import moment from 'moment';

import {UserContext} from '../contexts/User';

export default function ControlledTextField(props) {
    const {name, rules, label, views, format, readOnly, fullWidth, disabled, sx, ...rest} = props;
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    // const [open, setOpen] = useState(false);

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <DatePicker
                        
                        label={label}
                        format={format || dateFormat}
                        readOnly={readOnly}
                        disabled={disabled}
                        {...{
                            ...(sx && {sx}),
                            ...(format && {format}),
                            ...(views && {views})
                        }}
                        {...restField}
                        value={value || null}
                        onChange={date => onChange(date ? moment(date && date.toDate()) : null)}
                        slotProps={{
                            textField: {
                                ...rest,
                                helperText: null,
                                fullWidth,
                                // onClick: () => {
                                //     setOpen(true);
                                // }
                            },
                            // popper: {
                            //     onBlur: () => {
                            //         setOpen(false);
                            //     }
                            // },
                            // openPickerButton: {
                            //     onClick: () => {
                            //         setOpen(true);
                            //     }
                            // }
                        }}
                    />
                );
            }}
        />
    );
};
