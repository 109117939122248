import React, {useState, useEffect, useCallback, useContext} from 'react';
import {GRID_TREE_DATA_GROUPING_FIELD, GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid-pro';
import {useSnackbar} from 'notistack';
import {Box, useMediaQuery} from '@mui/material';
import {collection, getDocs, query, orderBy, where} from 'firebase/firestore';
import {useTheme} from '@mui/material/styles';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';

import {db} from '../firebase';
import {UserContext} from '../contexts/User';

import {Ranks, FirefighterRanks, Skills} from '../data/utils';
import {OFCMinimumStandards, Competencies} from '../data/standards';

import Training from '../dialogs/Training';

import Filter from '../components/Filter';

import {TrainingGrid, renderTrainingCell} from './matrix/Training';

const TrainingMatrix = () => {
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [training, setTraining] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [docs, setDocs] = useState([]);
    // const [availableFilters, setAvailableFilters] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {isOfficer} = currentUser || {};
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const methods = useForm({
        defaultValues: {
            textFilter: null,
            dateRange: [moment().startOf('year'), moment()]
        },
        mode: 'onChange'
    });
    const {watch} = methods;
    
    const dateRange = watch('dateRange');
    const textFilter = watch('textFilter');
    const toggleFilter = watch('toggleFilter');

    const onlyQualifications = (toggleFilter || []).includes('QUALIFICATIONS');
    const showAllSkills = (toggleFilter || []).includes('SHOW_ALL_SKILLS');
    const showSelection = (toggleFilter || []).includes('SELECTION');
    const showJIBC = (toggleFilter || []).includes('JIBC_NUMBER');
    const showJIBCPassword = (toggleFilter || []).includes('JIBC_PASSWORDS');
    const showDOB = (toggleFilter || []).includes('DOB');

    const handleCellClick = useCallback((params, e) => {
        const {field: rawField, row: member} = params;
        const {uid} = member || {};

        if (!uid) {
            return;
        }

        const splitField = rawField.split('.');
        const isLevel = splitField.length === 2;
        
        let field = splitField[splitField.length - 1];
        if (field === GRID_TREE_DATA_GROUPING_FIELD) {
            const {metaKey} = e;

            if (metaKey) {
                window.open(`/users/${uid}`);
                return;
            }

            window.location.href = `/users/${uid}`;
            
            return;
        }

        if (isLevel) {
            return;
        }

        setDialogOpen({
            field: rawField,
            isLevel,
            member
        });
    }, []);

    const fetchTraining = useCallback(async newDocs => {
        setLoading(true);

        const [startDate, endDate] = dateRange || [];

        try {
            const queryStartDate = moment(startDate).startOf('day').toDate();
            const queryEndDate = moment(endDate).endOf('day').toDate();

            const ref = collection(db, 'training');
            const q = query(ref, where('date', '>=', queryStartDate), where('date', '<=', queryEndDate));
            const raw = await getDocs(q);
            let training = [];

            raw.forEach(doc => {
                const {date, ...rest} = doc.data();

                training.push({
                    id: doc.id,
                    uid: doc.id,
                    ...rest,
                    date: date.toDate()
                });
            });

            setTraining(training);

            // update docs with latest training per member
            const docs = (newDocs || docs).map(doc => {
                const {uid} = doc;
                const memberTraining = training.filter(t => t.members.includes(uid));

                return {
                    ...doc,
                    training: memberTraining
                };
            });

            setDocs(docs);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, enqueueSnackbar, docs, dateRange]);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            const ref = collection(db, 'users');
            const q = query(ref, where('role', 'in', FirefighterRanks), orderBy('role', 'asc'));
            const snapshot = await getDocs(q);
                
            let docs = [];
            // let newAvailableFilters = [
            //     {
            //         type: 'station',
            //         value: '51',
            //         label: 'Station 51'
            //     },
            //     {
            //         type: 'station',
            //         value: '52',
            //         label: 'Station 52'
            //     },
            //     ...Object.keys(Ranks).map(rank => {
            //         return {
            //             type: 'rank',
            //             value: rank,
            //             label: Ranks[rank]
            //         }
            //     }),
            //     ...Object.keys(Skills).reduce((result, level) => {
            //         const {label} = Skills[level];

            //         result.push({
            //             type: 'level',
            //             value: level,
            //             label
            //         });

            //         return result;
            //     }, [])
            // ];
            
            snapshot.forEach(doc => {
                const data = doc.data();
                const {fullName, firstName, lastName, role} = data;
                const key = isSmall ? `${firstName[0]}. ${lastName}` : fullName;

                docs.push({
                    id: doc.id,
                    uid: doc.id,
                    ...data,
                    path: `${role ? Ranks[role] : 'No Rank'}/${key}`
                });

                //     newAvailableFilters.push({
                //         type: 'user',
                //         label: fullName,
                //         value: doc.id
                //     });
            });

            if (isSubscribed) {
                const sortedDocs = docs
                    .filter(doc => doc.deactivated !== true)
                    .sort((a, b) => {
                        return FirefighterRanks.indexOf(a.role) - FirefighterRanks.indexOf(b.role);
                    });

                await fetchTraining(sortedDocs);

                setFirstLoading(false);

                // setAvailableFilters(newAvailableFilters);
            }
        }
        
        fetch();
        
        return () => {
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, isSmall, dateRange]);

    useEffect(() => {
        let columns = [];

        // const columnStatusFilters = (textFilter || []).filter(filter => filter.type === 'status');
        // const columnLevelFilters = (textFilter || []).filter(filter => filter.type === 'level');
        // let columnSkillFilters = (textFilter || []).filter(filter => filter.type === 'skill');

        // const hasColumnFilters = columnLevelFilters.length > 0 || columnSkillFilters.length > 0;

        const addColumns = (parent, field = 'ofc', parentLabel) => {
            for (const [level, levelValue] of Object.entries(parent || OFCMinimumStandards)) {
                const existing = Skills[level] || Competencies[level];
                const {label: labelLevel, shortLabel} = existing || {label: level};

                if (!existing)  {
                    console.warn(`No label for ${level}`);
                }

                columns.push({
                    field: `${field}.${level}`,
                    headerName: [parentLabel, shortLabel || labelLevel].filter(Boolean).join(' '),
                    headerAlign: 'center',
                    sortable: false,
                    headerClassName: 'style-training-level',
                    cellClassName: 'style-training-level'
                });

                for (const competency of Object.keys(levelValue)) {
                    if (competency === 'levels') {
                        addColumns(parent[level].levels, `${field}.${level}.levels`, labelLevel);
                        continue;
                    }

                    const {label, shortLabel} = Competencies[competency] || {};
                    if (!Competencies[competency]) {
                        console.warn(`No label for ${competency}`);
                    }

                    columns.push({
                        field: `${field}.${level}.${competency}`,
                        headerName: shortLabel || label,
                        sortable: false,
                        width: 25,
                        headerAlign: 'left',
                        headerClassName: 'rotated',
                        renderCell: renderTrainingCell(`${field}.${level}.${competency}`)
                    });
                }
            }
        };

        addColumns(OFCMinimumStandards);

        // for (const [level, value] of Object.entries(Skills)) {
        //     const {label: headerName, shortLabel: shortHeaderName, width = 105, skills} = value;
        //     const alwaysShowLevel = columnLevelFilters.length > 0 && columnLevelFilters.some(filter => filter.value === level);

        //     const levelColumns = onlyQualifications ? [] : Object.entries(skills).reduce((result, [key, value]) => {
        //         const field = `skills.${level}.${key}`;
        //         let valueField = field;
        //         const skillIsFiltered = columnSkillFilters.some(filter => filter.value === field);
                
        //         if ((alwaysShowLevel || !hasColumnFilters) || skillIsFiltered) {
        //             let {label: headerName, width = 140, skill} = typeof value === 'string' ? {label: value} : value;

        //             if (skill) {
        //                 valueField = `skills.${skill}`;

        //                 const [, level, skillKey] = valueField.split('.');
        //                 headerName = get(Skills, `${level}.skills.${skillKey}`);
        //             }

        //             result.push({
        //                 field: [field, valueField].filter(Boolean).join(','),
        //                 headerName,
        //                 width,
        //                 headerAlign: 'center',
        //                 valueGetter: (value, row) => {
        //                     const {uid} = row;
        //                     const result = get(row, valueField, uid && 'INCOMPLETE');

        //                     if (typeof result === 'object') {
        //                         const {status, expiryDate} = value || {}; // TODO
        //                         if (expiryDate) {
        //                             return expiryDate;
        //                         }

        //                         return status;
        //                     }

        //                     return result;
        //                 },
        //                 renderCell: renderTrainingCell(valueField)
        //             });
        //         }

        //         return result;
        //     }, []);

        //     const extra = get(Skills, `${level}.extra`, false);

        //     if (levelColumns.length || (onlyQualifications && !extra)) {
        //         columns.push({
        //             field: level,
        //             headerName: shortHeaderName || headerName,
        //             width: isSmall ? 100 : width,
        //             headerAlign: 'center',
        //             sortable: false,
        //             headerClassName: 'style-training-level',
        //             cellClassName: 'style-training-level',
        //             renderCell: renderTrainingLevelCell(level)
        //         });

        //         columns.push(...levelColumns);
        //     }
        // }

        // const rowFilters = (textFilter || []).filter(filter => !['level', 'skill'].includes(filter.type));
        let filtered = docs;

        // if (rowFilters.length) {
        //     const rowFilterableFilters = rowFilters.filter(filter => ['user', 'station', 'rank'].includes(filter.type));

        //     if (rowFilterableFilters.length) {
        //         filtered = filtered.filter(doc => {
        //             let match = false;
                    
        //             for (const filter of rowFilterableFilters) {
        //                 const {type, value} = filter;

        //                 if (type === 'user') {
        //                     if (doc.uid === value) {
        //                         match = true;
        //                     }
        //                 } else if (type === 'station') {
        //                     if (doc.station === value) {
        //                         match = true;
        //                     }
        //                 } else if (type === 'rank') {
        //                     if (doc.role === value) {
        //                         match = true;
        //                     }
        //                 }
        //             }

        //             return match;
        //         });
        //     }

        //     if (columnStatusFilters.length) {
        //         filtered = filtered.filter(doc => {
        //             return columns.some(column => {
        //                 const {field: rawField} = column;

        //                 const splitField = rawField.split(',');
        //                 const isLevel = splitField.length === 1;

        //                 if (isLevel) {
        //                     return false;
        //                 }

        //                 const field = splitField[splitField.length - 1];
        //                 const value = get(doc, field) || {};
        //                 const {expiryDate, status} = typeof value === 'string' ? {status: value} : value;

        //                 const result = columnStatusFilters.some(filter => {
        //                     const {value: stage} = filter;

        //                     if (stage === 'INCOMPLETE') {
        //                         return !status || status !== 'COMPLETE';
        //                     } else if (stage === 'EXPIRING') {
        //                         return expiryDate && moment(expiryDate.toDate()).isBefore(moment().add(12, 'month'));
        //                     }

        //                     return status === stage;
        //                 });

        //                 return result;
        //             });
        //         });

        //         // show the column only if there are filters that match
        //         columns = columns.filter(column => {
        //             const {field: rawField} = column;

        //             const splitField = rawField.split(',');
        //             const isLevel = splitField.length === 1;

        //             if (isLevel) {
        //                 return true;
        //             }

        //             const field = splitField[splitField.length - 1];

        //             // get all skill values for this column
        //             const values = filtered.map(doc => {
        //                 const value = get(doc, field) || {};
        //                 const {expiryDate, status} = typeof value === 'string' ? {status: value} : value;
        //                 return {expiryDate, status};
        //             });

        //             return columnStatusFilters.some(filter => {
        //                 const {value: stage} = filter;

        //                 if (stage === 'INCOMPLETE') {
        //                     return values.some(value => !value.status || value.status !== 'COMPLETE');
        //                 } else if (stage === 'EXPIRING') {
        //                     return values.some(value => {
        //                         const {expiryDate} = value;
        //                         return expiryDate && moment(expiryDate.toDate()).isBefore(moment().add(12, 'month'));
        //                     });
        //                 }

        //                 return values.some(value => value.status === stage);
        //             });
        //         });

        //         // show the level only if there are skills under that level
        //         const columnSkills = columns.reduce((result, column) => {
        //             const {field: rawField} = column;

        //             const splitField = rawField.split(',');
        //             const isLevel = splitField.length === 1;

        //             if (isLevel) {
        //                 return result;
        //             }

        //             const [, field] = splitField;

        //             result.push(field);

        //             return result;
        //         }, []);

        //         columns = columns.filter(column => {
        //             const {field: rawField} = column;

        //             const splitField = rawField.split(',');
        //             const isLevel = splitField.length === 1;

        //             if (!isLevel) {
        //                 return true;
        //             }

        //             return columnSkills.some(skill => skill.match(rawField));
        //         });
        //     }
        // }

        setFilteredDocs(filtered);

        setColumns(columns);
    }, [docs, isSmall, showJIBC, showJIBCPassword, showDOB, textFilter, onlyQualifications, showAllSkills, training]);

    return (
        <FormProvider {...methods}>
            <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                {!!dialogOpen && (
                    <Training editable={isOfficer} open={!!dialogOpen} {...dialogOpen} onClose={() => setDialogOpen(false)} />
                )}

                <Filter loading={loading}>
                    {/* <AutocompleteField
                        label="Filter by Station, Rank, Level, Member or Status"
                        name="textFilter"
                        multiple
                        options={availableFilters}
                        filterSelectedOptions
                        disableCloseOnSelect
                        size="small"
                        sx={{flex: 1, mr: !isAtLeastMedium ? 1 : 0, mb: isAtLeastMedium ? 1 : 0}}
                        groupBy={option => {
                            const {type} = option;
                            return capitalize(type);
                        }}
                    /> */}
                </Filter>

                <Box sx={{flex: 1, position: 'relative'}}>
                    <Box sx={{inset: 0, position: 'absolute'}}>
                        <TrainingGrid
                            initialState={{
                                pinnedColumns: {
                                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_TREE_DATA_GROUPING_FIELD]
                                }
                            }}
                            experimentalFeatures={{
                                rowPinning: true
                            }}
                            checkboxSelection={showSelection}
                            treeData
                            getTreeDataPath={row => {
                                return row.path.split('/');
                            }}
                            groupingColDef={{
                                headerName: `${filteredDocs.length} members`,
                                width: isSmall ? 150 : 200,
                                hideable: false,
                                valueGetter: (value, row) => {
                                    const {fullName, firstName, lastName, email} = row || {};

                                    if (isSmall && fullName) {
                                        return `${firstName[0]}. ${lastName}`;
                                    }
                    
                                    return fullName || email;
                                },
                                cellClassName: params => {
                                    const {rowNode} = params;
                                    const {depth = 0} = rowNode;
                                    
                                    if (depth === 0) {
                                        return 'grouping-cell-header';
                                    }

                                    return 'grouping-cell';
                                }
                            }}
                            onCellClick={handleCellClick}
                            loading={firstLoading}
                            rows={filteredDocs}
                            columns={filteredDocs.length ? columns : []}
                            defaultGroupingExpansionDepth={-1}
                            className={onlyQualifications && 'only-qualifications'}
                            columnHeaderHeight={200}
                        />
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default TrainingMatrix;