import React, {forwardRef} from 'react';
import {Controller} from 'react-hook-form';

export default forwardRef((props, ref) => {
    const {name, rules, multiple, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <input
                        type="file"
                        hidden
                        {...rest}
                        {...restField}
                        multiple={multiple}
                        value=""
                        ref={ref}
                        onChange={e => {
                            if (multiple) {
                                onChange([
                                    ...value || [],
                                    ...Array.from(e.target.files)
                                ]);

                                return;
                            }

                            onChange(e.target.files[0]);
                        }}
                    />
                );
            }}
        />
    );
});
