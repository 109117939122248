import React from 'react';
import {useController} from 'react-hook-form';
import {TextField} from '@mui/material';
import {capitalize} from 'lodash';

export default function ControlledTextField(props) {
    const {name, label, required, rules: rawRules, helperText, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, ...restField} = field;

    return (
        <TextField
            variant="outlined"
            label={fieldLabel}
            required={required}
            value={value || ''}
            {...restField}
            error={!!error}
            helperText={error && helperText !== false ? error.message : helperText}
            {...rest}
        />
    );
};
