import React from 'react';
import {Box} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import moment from 'moment';

import DateRangePickerButtonField from '../form/DateRangePickerButtonField.js';

const shortcutsItems = [
    {
        label: 'This Week',
        getValue: () => {
            return [moment().startOf('week'), moment().endOf('week')];
        }
    },
    {
        label: 'Last Week',
        getValue: () => {
            const previous = moment().subtract(7, 'day');
            return [previous.clone().startOf('week'), previous.clone().endOf('week')];
        }
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            return [moment().subtract(7, 'day'), moment()];
        }
    },
    {
        label: 'This Month',
        getValue: () => {
            return [moment().startOf('month'), moment().endOf('month')];
        }
    },
    {
        label: 'Last Month',
        getValue: () => {
            const previous = moment().subtract(1, 'month');
            return [previous.clone().startOf('month'), previous.clone().endOf('month')];
        }
    },
    {
        label: 'This Year',
        getValue: () => {
            return [moment().startOf('year'), moment().endOf('year')];
        }
    },
    {
        label: 'Last Year',
        getValue: () => {
            const previous = moment().subtract(1, 'year');
            return [previous.clone().startOf('year'), previous.clone().endOf('year')];
        }
    },
    {
        label: 'All Time',
        getValue: () => {
            const previous = moment().subtract(10, 'year');
            return [previous.clone().startOf('year'), moment()];
        }
    }
];

const Filter = props => {
    const {loading, onSubmit, children} = props;
    const {handleSubmit} = useFormContext();

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mb: 1}} component="form" onSubmit={handleSubmit(onSubmit)}>
            {children}

            <DateRangePickerButtonField
                name="dateRange"
                disabled={loading}
                slotProps={{
                    shortcuts: {
                        items: shortcutsItems
                    }
                }}
            />
        </Box>
    );
};

export default Filter;