import React, {useState, useCallback, useContext, useEffect} from 'react';
import {Skeleton, IconButton, InputAdornment, Box, Tabs, Tab, Typography, Divider, Grid2 as Grid, Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {useForm, FormProvider} from 'react-hook-form';
import {doc, onSnapshot, updateDoc, collection, query, where, getDocs} from 'firebase/firestore';
import {useParams} from 'react-router-dom';
import {httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';
import {omit} from 'lodash';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';

import {UserContext} from '../contexts/User';
import {SettingsContext} from '../contexts/Settings';
import {db, functions} from '../firebase';
import {hasFeature} from '../features';

import {processUserName, processRawDoc, verifyOfficer, ensureJSDates, ensureMomentDates, uploadImage, Ranks} from '../data/utils';

import useDocumentTitle from '../hooks/useDocumentTitle';

import TextField from '../form/TextField.js';
import PasswordField from '../form/PasswordField.js';
import SelectField from '../form/SelectField.js';
import CheckboxField from '../form/CheckboxField';
import DatePickerField from '../form/DatePickerField';
import PhoneField from '../form/PhoneField';
import ImageUploadField from '../form/ImageUploadField';
import StationsField from '../form/StationsField';

import MemberSkills from './member/MemberSkills';
import Statistics from './member/Statistics';
import JIBCTranscript from './member/JIBCTranscript';
import JIBCConsent from './member/JIBCConsent';

export default function User(props) {
    const {isProfile, activeTab = 'edit'} = props;

    const {id: uid} = useParams();
    const isNew = !uid && !isProfile;
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const [editingEmail, setEditingEmail] = useState(isNew);
    // const [impersonating, setImpersonating] = useState(false);
    const navigate = useNavigate();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    
    const {limitToDomain, domain, usesJIBC} = useContext(SettingsContext);
    const {currentUser} = useContext(UserContext);
    const [user, setUser] = useState(isProfile ? currentUser : undefined);
    const deleteUser = httpsCallable(functions, 'deleteUser');
    const registerUser = httpsCallable(functions, 'registerUser');
    const updateUserEmail = httpsCallable(functions, 'updateUserEmail');
    const updateUserPassword = httpsCallable(functions, 'updateUserPassword');
    // const impersonateUser = httpsCallable(functions, 'impersonateUser');

    const isOfficer = verifyOfficer(currentUser);
    const {isAdmin: currentUserIsAdmin} = currentUser || {};

    let pattern;
    if (limitToDomain) {
        pattern = {
            value: new RegExp(`^[A-Z0-9._%+-]+@${domain}$`, 'i'),
            message: `You must use an @${domain} email address`
        };
    } else {
        pattern = {
            value: /\S+@\S+\.\S+/,
            message: 'Invalid email address'
        }
    }

    const methods = useForm({
        defaultValues: {
            email: '',
            role: '',
            dob: moment(),
            station: '',
            firstName: '',
            lastName: '',
            imageUrl: '',
            imageThumbnailUrl: '',
            imageFile: '',
            employeeId: '',
            jibcNumber: '',
            phone: '',
            ...user,
            settings: {
                dateFormat: ''
            }
        },
        mode: 'onChange'
    });
    const {handleSubmit, reset, watch, formState} = methods;
    const {dirtyFields} = formState;

    const imageFile = watch('imageFile');
    const password = watch('password');
    const registered = watch('registered');
    const email = watch('email');
    const fullName = watch('fullName');

    const dateFormat = watch('settings.dateFormat');
    const dateFormatLong = watch('settings.dateFormatLong');
    const dateFormatShort = watch('settings.dateFormatShort');

    const fetch = useCallback(() => {
        if (uid) {
            const ref = doc(db, 'users', uid);
            const unsubscribe = onSnapshot(ref, snapshot => {
                const row = processRawDoc(snapshot);
                reset(row);
                setUser(row);
                setLoading(false);
            });

            return unsubscribe;
        }
    }, [uid, db]);
    
    useEffect(() => {
        fetch();
    }, [db, uid]);

    useEffect(() => {
        if (isProfile) {
            setUser(currentUser);
            reset(currentUser);
        }
    }, [user, currentUser, isProfile, reset, navigate, isOfficer]);

    useDocumentTitle(isProfile ? 'Profile' : (isNew ? 'New User' : (fullName || email)));

    // const handleImpersonate = useCallback(async() => {
    //     try {
    //         setImpersonating(true);

    //         const {data} = await impersonateUser({uid});
    //         const {token} = data;

    //         const currentUserToken = await auth.currentUser.getIdToken();
    //         localStorage.setItem('impersonating', currentUserToken);

    //         await signInWithCustomToken(auth, token);
    //     } catch(e) {
    //         enqueueSnackbar(e.message, {variant: 'error'});
    //     }

    //     setImpersonating(false);
    // }, [uid, auth, impersonateUser, enqueueSnackbar]);

    const handleDelete = useCallback(() => {
        const onDelete = async() => {
            try {
                setDeleting(true);

                await deleteUser({uid});

                navigate(-1);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this member? This will also delete this account', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Dismiss
                        </Button>
                    </>
                );
            }
        });
    }, [enqueueSnackbar, navigate, uid, closeSnackbar, deleteUser]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const newData = ensureJSDates(processUserName(omit(data, [
            'isOfficer',
            'imageFile',
            'password',
            'confirmPassword'
        ])));
        const {email} = data;

        try {
            if (isNew) {
                const ref = collection(db, 'users');
                const q = query(ref, where('email', '==', email));
                const raw = await getDocs(q);
                const {size} = raw;
                
                if (size > 0) {
                    enqueueSnackbar(`Member with email '${email}' already exists`, {variant: 'error'});
                } else {
                    const {data} = await registerUser({
                        email,
                        password
                    });
                    const {uid} = data || {};
                    
                    await updateDoc(doc(db, 'users', uid), newData);

                    if (imageFile) {
                        await uploadImage(`users/${uid}`, imageFile);
                    }

                    enqueueSnackbar('User created', {variant: 'success'});

                    navigate(-1);
                }
            } else {
                const {id} = newData;
                const docRef = doc(db, 'users', id);

                if (email && Object.keys(dirtyFields).includes('email')) {
                    await updateUserEmail({uid: id, email, throws: false});
                }

                if (password && Object.keys(dirtyFields).includes('password')) {
                    await updateUserPassword({uid: id, password, throws: false});
                }

                let image;
                if (imageFile) {
                    image = await uploadImage(`users/${id}`, imageFile);
                } else if (imageFile === null) {
                    image = null;
                }
    
                await updateDoc(docRef, {
                    ...newData,
                    image
                });

                reset(ensureMomentDates({
                    ...newData,
                    image,
                    password: null,
                    confirmPassword: null
                }))

                setLoading(false);
                setEditingEmail(false);

                enqueueSnackbar('Member updated', {variant: 'success'});

                navigate(-1);
            }
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, navigate, isOfficer, updateUserPassword, currentUserIsAdmin, enqueueSnackbar, imageFile, currentUser, isProfile, isNew, dirtyFields]);

    const tabs = [
        {key: 'edit', label: isNew ? 'New User' : isProfile ? 'Edit Profile' : 'Edit Member'}
    ];

    if (!isNew) {
        tabs.push({key: 'skills', label: 'Skills'});
    }

    const handleTabChange = useCallback((event, index) => {
        const base = isProfile ? '/profile' : `/users/${uid}`;
        const {key} = tabs[index] || {};

        if (key === 'edit') {
            navigate(`${base}`);
        } else {
            navigate(`${base}/${key}`);
        }
    }, [navigate, uid, isProfile]);

    if (usesJIBC) {
        tabs.push({key: 'transcript', label: 'JIBC Transcript'});
        tabs.push({key: 'jibcConsent', label: 'JIBC Consent'});
    }

    if (hasFeature('memberStatistics')) {
        tabs.push({key: 'statistics', label: 'Statistics'});
    }

    return (
        <FormProvider {...methods}>
            <Typography variant="h5" gutterBottom>{isNew ? 'New User' : (fullName ? fullName : <Skeleton width={180} />)}</Typography>

            {!isNew && (
                <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
                    <Tabs value={tabs.findIndex(tab => tab.key === activeTab)} onChange={handleTabChange}>
                        {tabs.map(tab => (
                            <Tab key={tab.key} label={tab.label} />
                        ))}
                    </Tabs>
                </Box>
            )}
            {activeTab === 'edit' && (
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                    <Grid container spacing={2}>
                        {!isNew && (
                            <Grid size={{xs: 12, sm: 4}}>
                                <ImageUploadField name="image" />
                            </Grid>
                        )}
                        <Grid size={{xs: 12, sm: isNew ? 12 : 8}}>
                            <TextField
                                sx={{mt: 2}}
                                required
                                fullWidth
                                label="Email"
                                name="email"
                                autoComplete="email"
                                disabled={!editingEmail || loading}
                                rules={{
                                    required: true,
                                    pattern
                                }}
                                slotProps={{
                                    ...!editingEmail && {
                                        input: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setEditingEmail(!editingEmail)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                }}

                            />

                            {(isNew || (currentUserIsAdmin && registered) || isProfile) && (
                                <PasswordField
                                    sx={{mt: 2}}
                                    fullWidth
                                    label="New Password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    disabled={loading}
                                />
                            )}
                            {password && (
                                <>
                                    <PasswordField
                                        sx={{mt: 2}}
                                        rules={{
                                            required: true,
                                            validate: value => value !== password ? 'Passwords do not match' : true
                                        }}
                                        fullWidth
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        autoComplete="new-password"
                                        type="password"
                                        disabled={loading}
                                    />
                                </>
                            )}
                            
                            <Grid container spacing={1} sx={{mt: 1}}>
                                {isOfficer && (
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <SelectField
                                            sx={{mt: 1}}
                                            margin="normal"
                                            fullWidth
                                            label="Rank"
                                            name="role"
                                            allowBlank
                                            disabled={loading}
                                            options={Object.keys(Ranks).map(value => {
                                                return {
                                                    value,
                                                    label: Ranks[value]
                                                };
                                            })}
                                        />
                                    </Grid>
                                )}
                                <Grid size={{xs: 12, sm: 6}}>
                                    <StationsField
                                        sx={{mt: 1}}
                                        fullWidth
                                        allowBlank
                                        disabled={loading}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h6" sx={{mt: 2, mb: 1}}>Personal Information</Typography>
                            <Divider />

                            <Grid container spacing={1} sx={{pt: 1}}>
                                <Grid size={{xs: 12, sm: 6}}>
                                    <TextField
                                        sx={{mt: 1}}
                                        fullWidth
                                        label="First Name"
                                        name="firstName"
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid size={{xs: 12, sm: 6}}>
                                    <TextField
                                        sx={{mt: 1}}
                                        fullWidth
                                        label="Last Name"
                                        name="lastName"
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid size={{xs: 12, sm: 6}}>
                                    <DatePickerField
                                        sx={{mt: 1}}
                                        label="Date of Birth"
                                        name="dob"
                                        fullWidth
                                        disabled={loading}
                                    />
                                </Grid>

                                {(isOfficer || isProfile) && (
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <PhoneField
                                            sx={{mt: 1}}
                                            fullWidth
                                            label="Phone"
                                            name="phone"
                                            disabled={loading}
                                        />
                                    </Grid>
                                )}

                                {currentUserIsAdmin && (
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <TextField
                                            sx={{mt: 1}}
                                            fullWidth
                                            label="Employee ID"
                                            name="employeeId"
                                            disabled={loading}
                                        />
                                    </Grid>
                                )}

                                {usesJIBC && (
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <TextField
                                            sx={{mt: 1}}
                                            fullWidth
                                            label="JIBC #"
                                            name="jibcNumber"
                                            disabled={loading}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            {(isProfile || currentUserIsAdmin) && (
                                <>
                                    <Typography variant="h6" sx={{mt: 2, mb: 1}}>Settings</Typography>
                                    <Divider />

                                    <Grid container spacing={1}>
                                        <Grid size={{xs: 12, sm: 4}}>
                                            <TextField
                                                sx={{mt: 1}}
                                                label="Date Format"
                                                name="settings.dateFormat"
                                                placeholder="DD/MM/yyyy"
                                                fullWidth
                                                disabled={loading}
                                                helperText={moment().format(dateFormat || 'DD/MM/YYYY')}
                                            />
                                        </Grid>

                                        <Grid size={{xs: 12, sm: 4}}>
                                            <TextField
                                                sx={{mt: 1}}
                                                label="Short Date Format"
                                                name="settings.dateFormatShort"
                                                placeholder="MMMM yyyy"
                                                fullWidth
                                                disabled={loading}
                                                helperText={moment().format(dateFormatShort || 'MMMM YYYY')}
                                            />
                                        </Grid>

                                        <Grid size={{xs: 12, sm: 4}}>
                                            <TextField
                                                sx={{mt: 1}}
                                                label="Long Date Format"
                                                name="settings.dateFormatLong"
                                                placeholder="LLL"
                                                fullWidth
                                                disabled={loading}
                                                helperText={moment().format(dateFormatLong || 'LLL')}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {currentUserIsAdmin && (
                                <>
                                    <Typography variant="h6" sx={{mt: 2, mb: 1}}>Administration</Typography>
                                    <Divider />

                                    <CheckboxField sx={{mr: 1}} name="deactivated" label="No longer active?" />
                                    <CheckboxField name="isAdmin" label="Is Admin?" />
                                </>
                            )}

                            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                                {/* {currentUserIsAdmin && canImpersonate && (
                                    <LoadingButton
                                        size="large"
                                        onClick={handleImpersonate}
                                        disabled={impersonating}
                                        loading={impersonating}
                                        sx={{mr: 1}}
                                    >
                                        Impersonate
                                    </LoadingButton>
                                )} */}

                                {(isOfficer && !isProfile) && (
                                    <LoadingButton
                                        size="large"
                                        onClick={handleDelete}
                                        disabled={loading || deleting}
                                        loading={deleting}
                                        sx={{mr: 1}}
                                    >
                                        Delete Member
                                    </LoadingButton>
                                )}

                                <LoadingButton
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={loading || deleting}
                                    loading={loading || deleting}
                                >
                                    {isProfile ? 'Save Profile' : (isNew ? 'Create Member' : 'Update Member')}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {activeTab === 'skills' && (
                <MemberSkills user={user} />
            )}
            {activeTab === 'transcript' && (
                <JIBCTranscript user={user} />
            )}
            {activeTab === 'jibcConsent' && (
                <JIBCConsent user={user} />
            )}
            {activeTab === 'statistics' && (
                <Statistics user={user} />
            )}
        </FormProvider>
    );
}